import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_7/slide1';

import SubSlide1 from './slides/subSlide1';
import SubSlide2 from './slides/subSlide2';
import SubSlide3 from './slides/subSlide3';
import SubSlide4 from './slides/subSlide4';
import SubSlide5 from './slides/subSlide5';
import SubSlide7 from './slides/subSlide7';
import SubSlide8 from './slides/subSlide8';

const getRandom = () => Math.random();

const slides = [
  <SubSlide1 key={getRandom} />,
  <SubSlide2 key={getRandom} />,
  <SubSlide3 key={getRandom} />,
  <SubSlide4 key={getRandom} />,
  <SubSlide5 key={getRandom} />,
  <SubSlide7 key={getRandom} />,
  <SubSlide8 key={getRandom} />,
];

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      text1: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "slide-4-7-1-mobile"}}) {
        body
      }
      text2: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "slide-4-7-2-mobile"}}) {
        body
      }
      file(relativePath: { eq: "chapter_4/sub_7/mobile/4-7-1-bg.png"}) {
        childImageSharp {
            fixed(width: 1920) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
      }
      timelineTitels: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "4-7-timeline"}}) {
        exports {
          titleArray {
            index
            position
            text
          }
        }
      }
      bulletsInfo: mdx(frontmatter: {title: {eq: "bullets-info-mobile"}, language: {eq: "DE"}}) {
        exports {
          bullets {
            name
            caliber
          }
        }
      }
    }
  `);

  return (
    <Template query={query} slides={slides} title="Teil VII" buttonText="Siehe die Karte" />
  );
};


export default Slide;
